import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import Backends from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from "./conf/config"
import packageJson from "../package.json";

const LOCAL = new HttpBackend();
const S3Backend = new HttpBackend();

const constructBackends = () => {
    console.log("process.env.REACT_APP_STAGE", process.env.REACT_APP_STAGE)
    if (!process.env.REACT_APP_STAGE) {
        const Backend = require("i18next-fs-backend");
        const Test = new Backend();
        return {
            backends: [
                Test
            ],
            backendOptions: [
                {
                    loadPath: `${__dirname}/../src/locales/{{lng}}/translation.json`,
                }
            ]
        }
    } else if (process.env.REACT_APP_STAGE === "production" || process.env.REACT_APP_STAGE === "demo") {
        console.log("IF2")
        return {
            backends: [
                LOCAL
            ],
            backendOptions: [
                {
                    loadPath: `/locales/{{lng}}/{{ns}}.json?v=${packageJson.version}`
                }
            ]
        }
    } else {
        console.log("IF3")
        return {
            backends: [
                S3Backend, // primary
                LOCAL  // fallback
            ],
            backendOptions: [
                {
                    loadPath: `${config.apiGateway.URL}general2/translations?locale={{lng}}&ns={{ns}}` // xhr load path for my own fallback
                },
                {
                    loadPath: `/locales/{{lng}}/{{ns}}.json?v=${packageJson.version}`
                }
            ]
        }
    }
};

export const ns = ["login", "translation", "common"];

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
    .use(Backends)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // lng: 'cimode', // overrides detected
        backend: constructBackends(),
        fallbackNS: ['login', 'translation', 'common'],
        fallbackLng: {
            'pl': ['en', 'ru', 'es'],
            'en': ['es', 'ru', 'pl'],
            'es': ['en', 'ru', 'pl'],
            'ru': ['pl', 'en', 'es'],
            'default': ['en', 'pl', 'es', 'ru']
        },
        debug: false,
        // debug: true,
        ns,
        interpolation: {
            format: function(value, format) {
                if (format === 'uppercase') return value.toUpperCase();
                if (format === 'bold') return value.bold();
                return value;
            },
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
            wait: true
        }
    });
export default i18n;
